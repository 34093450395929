import Link from 'next/link';
import styled from 'styled-components';

const StyledNewApplication = {
  ApplyLink: styled(Link)`
    all: unset;
    cursor: pointer;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.cta.primary.default.background};
    padding: 8px 26px;
    height: fit-content;
    color: ${({ theme }) => theme.cta.primary.default.color};
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    &:hover {
      background-color: ${({ theme }) => theme.cta.primary.hover.background};
    }
    transition: background-color 0.3s ease;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 14px 20px;
      font-size: 16px;
      line-height: 24px;
    }
  `,
};

export default StyledNewApplication;
