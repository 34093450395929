import { CUSTOMER_PORTAL_ROUTES, NAMESPACE, ROUTES } from '@models/enums';
import { LoanStage } from '@pinecorpca/evergreen';
import { useEntitiesQuery } from 'hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { MAX_NUMBER_OF_APPLICATIONS } from './new-application.constants';
import Styled from './NewApplication.styled';

const NewApplication = () => {
  const { t } = useTranslation(NAMESPACE.CUSTOMERPORTAL);
  const {
    query: { applicationId },
  } = useRouter();
  const { data: entities, isLoading: entitiesLoading } = useEntitiesQuery();

  const isInCompleteEntities = useMemo(
    () => entities?.some((entity) => entity?.application?.loanStage === LoanStage.AppCreated),
    [entities]
  );

  if ((entities?.length ?? 0) >= MAX_NUMBER_OF_APPLICATIONS || isInCompleteEntities || entitiesLoading) {
    return null;
  }

  return (
    <Styled.ApplyLink
      data-testid="multi-application-create-link"
      href={`/${ROUTES.CUSTOMER_PORTAL}/${applicationId}/${CUSTOMER_PORTAL_ROUTES.CREATE}`}
    >
      {t('CREATE_APPLICATION_LINK')}
    </Styled.ApplyLink>
  );
};

export default NewApplication;
