import { LeadSource, PropertyOccupationType } from '@pinecorpca/evergreen';
import { usePartnerContext } from 'contexts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

export const useIntentions = () => {
  const { partner } = usePartnerContext();
  const { enableReferral } = useFlags();

  return useMemo(
    () => [
      {
        id: 'OWNER_OCCUPIED',
        value: PropertyOccupationType.OwnerOccupied,
        label: 'FLOW_OCCUPATION_OCCUPIED',
      },
      {
        id: 'RENTAL',
        value: PropertyOccupationType.Rental,
        label: 'FLOW_OCCUPATION_RENTAL',
        invalid: !enableReferral || partner !== LeadSource.Wealthsimple,
        errorMessage: 'FLOW_REFINANCE_PROPERTY_USAGE_INVESTMENT_ERROR',
      },
      {
        id: 'SECOND_HOME',
        value: PropertyOccupationType.SecondHome,
        label: 'FLOW_OCCUPATION_SECOND',
      },
    ],
    [enableReferral, partner]
  );
};
