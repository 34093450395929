import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { useTranslation } from 'next-i18next';
import { Button } from '@pinecorpca/spruce';
import { useCallback } from 'react';

type Props = {
  onClick: () => void;
  visible: boolean;
};

const BackButton = ({ onClick, visible }: Props) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick();
    },
    [onClick]
  );

  return (
    <Button
      color="secondary"
      onClick={handleOnClick}
      data-testid="question-back-cta"
      disabled={!visible}
      style={{ visibility: visible ? 'inherit' : 'hidden', width: 'fit-content' }}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
      {t('BACK')}
    </Button>
  );
};

export default BackButton;
