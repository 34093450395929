import { WS_TIER, WS_TIER_MAP } from '@/lib/partner';
import { Alert } from '@components/common';
import { ROUTES, SHORT_APP_ROUTES } from '@models/enums';
import { FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { ConstructionType, DealPurpose, LeadSource, PurchaseTimeframe } from '@pinecorpca/evergreen';
import { usePartnerContext, useShortAppContext } from 'contexts';
import { addYears } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { isAfterOrEqual } from 'utils';
import { TierRebate } from '../components/Partner';
import TierOption from '../components/Partner/TierOption/TierOption';
import { useIntentions } from '../hooks/useIntentions';
import { PropertyTypeOptions } from '../models/intentions.model';

const useShortAppForm = (): FlowRoot => {
  const { partner } = usePartnerContext();
  const { restrictClosingDate, wsCashbackPromoDate } = useFlags();
  const { body } = useShortAppContext();
  const isWealthsimple = partner === LeadSource.Wealthsimple;
  const isCashbackActive = useMemo(() => isAfterOrEqual(new Date(), new Date(wsCashbackPromoDate)), [wsCashbackPromoDate]);
  const propertyOccupationType = useIntentions();

  return useMemo<FlowRoot>(
    () => ({
      root: 'how_can_we_help',
      id: 'short-app',
      pageTitle: 'FLOW_GET_STARTED',
      nextSecUrl: `/${ROUTES.GET_STARTED}/${SHORT_APP_ROUTES.REGISTER}`,
      how_can_we_help: {
        id: 'how_can_we_help',
        type: 'options',
        header: 'FLOW_HEADER',
        next: FlowState.SEPARATE,
        gap: 15,
        column: 1,
        skipApiCall: true,
        fieldName: 'dealPurpose',
        options: [
          {
            id: 'new_mortgage',
            value: DealPurpose.Purchase,
            label: 'FLOW_NEW_MORTGAGE',
            next: 'madeOffer_property_usage',
          },
          {
            id: 'refinance_mortgage',
            value: DealPurpose.Refinance,
            label: 'FLOW_REFINANCE_SHORT_APP',
            next: 'refinance_property_usage',
          },
          {
            id: 'switch_transfer_mortgage',
            value: DealPurpose.SwitchTransfer,
            label: 'FLOW_SWITCH_TRANSFER',
            next: 'switch_property_usage',
          },
          {
            id: 'pre_approval',
            value: DealPurpose.PreApproval,
            label: 'FLOW_PRE_APPROVAL',
            next: 'researching_property_usage',
          },
        ],
      },
      // Making offer
      madeOffer_property_usage: {
        id: 'madeOffer_property_usage',
        type: 'options',
        header: 'FLOW_PROPERTY_USAGE',
        next: 'madeOffer_property_address',
        gap: 15,
        column: 1,
        skipApiCall: true,
        fieldName: 'propertyOccupationType',
        options: propertyOccupationType,
      },
      madeOffer_property_address: {
        id: 'madeOffer_property_address',
        type: 'form',
        header: 'FLOW_PROPERTY_ADDRESS_QUESTION',
        next: 'madeOffer_propertyType',
        gap: 15,
        column: 1,
        skipApiCall: true,
        inputs: [
          {
            id: 'property_address',
            label: 'FLOW_PROPERTY_ADDRESS',
            type: 'address',
            fieldName: 'address',
            inputProps: {
              validateProvince: true,
            },
          },
        ],
      },
      madeOffer_propertyType: {
        id: 'madeOffer_propertyType',
        type: 'options',
        header: 'FLOW_PROPERTY_TYPE',
        next: 'madeOffer_existing_new_build',
        style: { gridTemplateColumns: '1fr 1fr', gap: '25px' },
        skipApiCall: true,
        fieldName: 'propertyType',
        options: cloneDeep(PropertyTypeOptions),
      },
      madeOffer_existing_new_build: {
        id: 'madeOffer_existing_new_build',
        type: 'options',
        header: 'PROPERTY_PURCHASE_TYPE_HEADER',
        next: 'madeOffer_downpayment',
        gap: 15,
        column: 1,
        skipApiCall: true,
        fieldName: 'constructionType',
        options: [
          {
            id: 'existing',
            value: ConstructionType.Existing,
            label: 'PROPERTY_PURCHASE_TYPE_OPTION1',
          },
          {
            id: 'new_build',
            value: ConstructionType.New,
            label: 'PROPERTY_PURCHASE_TYPE_OPTION2',
          },
        ],
      },
      madeOffer_downpayment: {
        id: 'madeOffer_downpayment',
        type: 'form',
        header: 'PROPERTY_DOWNPAYMENT_HEADER',
        next: 'madeOffer_know_closingDate',
        fieldName: 'totalDownPayment',
        skipApiCall: true,
        inputs: [
          {
            id: 'downpayment',
            type: 'downpayment',
          },
        ],
      },
      madeOffer_know_closingDate: {
        id: 'madeOffer_know_closingDate',
        type: 'options',
        header: 'FLOW_KNOW_CLOSING_DATE',
        next: FlowState.SEPARATE,
        gap: 15,
        column: 1,
        skipApiCall: true,
        options: [
          {
            id: 'YES_I_KNOW',
            value: 'true',
            label: 'FLOW_YES',
            next: 'madeOffer_closingDate',
          },
          {
            id: 'NOT_SURE',
            value: 'false',
            label: 'FLOW_NOT_SURE_YET',
            next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
          },
        ],
      },
      madeOffer_closingDate: {
        id: 'madeOffer_closingDate',
        header: 'FLOW_WHEN_CLOSING_DATE',
        type: 'form',
        next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
        subHeader: () => (restrictClosingDate ? <Alert type="warning" message="CLOSING_DATE_ERROR" /> : undefined),
        inputs: [
          {
            id: 'closingDate',
            label: 'FLOW_CLOSING_DATE',
            type: 'date',
            fieldName: 'closingDate',
            from: new Date(),
            to: addYears(new Date(), 100),
            rules: {
              required: {
                value: true,
                message: 'ERROR_CLOSING_DATE_REQUIRED',
              },
            },
          },
        ],
      },
      // Just researching
      researching_property_usage: {
        id: 'researching_property_usage',
        type: 'options',
        header: 'FLOW_PROPERTY_USAGE',
        next: 'researching_property_address',
        gap: 15,
        column: 1,
        skipApiCall: true,
        fieldName: 'propertyOccupationType',
        options: propertyOccupationType,
      },
      researching_property_address: {
        id: 'researching_property_address',
        type: 'form',
        header: 'FLOW_INTERESTED_AREA',
        next: 'researching_propertyType',
        gap: 15,
        column: 1,
        skipApiCall: true,
        buildPayload: (answer: Record<string, any>): Record<string, any> => ({
          interestedLocation: answer?.interestedLocation?.formattedAddress,
          ...(answer?.interestedLocation?.province || answer?.interestedLocation?.city || answer?.interestedLocation?.postalCode
            ? {
                address: {
                  province: answer?.interestedLocation?.province || null,
                  city: answer?.interestedLocation?.city || null,
                  postalCode: answer?.interestedLocation?.postalCode || null,
                },
              }
            : {}),
        }),
        inputs: [
          {
            id: 'property_address',
            label: 'Address',
            type: 'autocomplete',
            hideLabel: true,
            placeholder: 'FLOW_ADDRESS_INTERESTED',
            fieldName: 'interestedLocation',
            rules: {
              required: {
                value: true,
                message: 'ADDRESS_INTERESTED_ERROR',
              },
              invalid: {
                value: true,
                message: 'FORM_PROVINCE_INVALID',
              },
            },
          },
        ],
      },
      researching_propertyType: {
        id: 'researching_propertyType',
        type: 'options',
        header: 'FLOW_PROPERTY_TYPE',
        next: 'researching_downpayment',
        style: { gridTemplateColumns: '1fr 1fr', gap: '25px' },
        fieldName: 'propertyType',
        options: cloneDeep(PropertyTypeOptions),
      },
      researching_downpayment: {
        id: 'researching_downpayment',
        type: 'form',
        header: 'PROPERTY_PRE_APPROVAL_DWNPMT_HEADER',
        next: 'researching_plan_to_buy',
        fieldName: 'totalDownPayment',
        skipApiCall: true,
        inputs: [
          {
            id: 'downpayment',
            type: 'downpayment',
            inputProps: {
              isPreApproval: true,
            },
          },
        ],
      },
      researching_plan_to_buy: {
        id: 'researching_plan_to_buy',
        type: 'options',
        next: 'researching_realtor',
        header: 'PRE_APPROVAL_PLAN_TO_BUY',
        gap: 25,
        column: 1,
        fieldName: 'purchaseTimeFrame',
        options: Object.entries(PurchaseTimeframe).map(([key, value]) => ({
          id: key,
          value,
          label: `FLOW_${value}`,
          next: 'researching_realtor',
        })),
      },
      researching_realtor: {
        id: 'researching_realtor',
        type: 'options',
        header: 'PRE_APPROVAL_REALTOR',
        next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
        fieldName: 'workingWithRealtor',
        options: [
          {
            id: 'YES',
            value: 'true',
            label: 'FLOW_YES',
            next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
          },
          {
            id: 'NO',
            value: 'false',
            label: 'FLOW_NO',
            next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
          },
        ],
      },
      refinance_property_usage: {
        id: 'refinance_property_usage',
        type: 'options',
        header: 'FLOW_REFINANCE_PROPERTY_USAGE',
        next: 'refinance_property_address',
        gap: 15,
        column: 1,
        skipApiCall: true,
        fieldName: 'propertyOccupationType',
        options: propertyOccupationType,
      },
      refinance_property_address: {
        id: 'refinance_property_address',
        type: 'form',
        header: 'FLOW_PROPERTY_ADDRESS_QUESTION',
        next: 'refinance_property_value',
        gap: 15,
        column: 1,
        skipApiCall: true,
        inputs: [
          {
            id: 'property_address',
            label: 'FLOW_PROPERTY_ADDRESS',
            type: 'address',
            fieldName: 'address',
            inputProps: {
              validateProvince: true,
            },
          },
        ],
      },
      refinance_property_value: {
        id: 'refinance_property_value',
        header: 'FLOW_SWITCH_MORTGAGE_AMOUNT',
        type: 'form',
        next: 'refinance_know_closingDate',
        skipApiCall: true,
        inputs: [
          {
            id: 'loanAmount',
            type: 'loanAmount',
            inputProps: { refinance: true },
          },
        ],
      },
      refinance_know_closingDate: {
        id: 'refinance_know_closingDate',
        type: 'options',
        header: 'FLOW_KNOW_REFINANCE_DATE',
        next: FlowState.SEPARATE,
        gap: 15,
        column: 1,
        skipApiCall: true,
        options: [
          {
            id: 'YES_I_KNOW',
            value: 'true',
            label: 'FLOW_YES',
            next: 'refinance_date',
          },
          {
            id: 'NOT_SURE',
            value: 'false',
            label: 'FLOW_NOT_SURE_YET',
            next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
          },
        ],
      },
      refinance_date: {
        id: 'refinance_date',
        header: 'FLOW_WHEN_REFINANCE_DATE',
        type: 'form',
        next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
        subHeader: () => (restrictClosingDate ? <Alert type="warning" message="CLOSING_DATE_ERROR" /> : undefined),
        inputs: [
          {
            id: 'refinanceDate',
            label: 'FLOW_REFINANCE_DATE',
            type: 'date',
            fieldName: 'closingDate',
            from: new Date(),
            to: addYears(new Date(), 100),
            rules: {
              required: {
                value: true,
                message: 'ERROR_CLOSING_DATE_REQUIRED',
              },
            },
          },
        ],
      },
      switch_property_usage: {
        id: 'switch_property_usage',
        type: 'options',
        header: 'FLOW_REFINANCE_PROPERTY_USAGE',
        next: 'switch_property_address',
        gap: 15,
        column: 1,
        skipApiCall: true,
        fieldName: 'propertyOccupationType',
        options: propertyOccupationType,
      },
      switch_property_address: {
        id: 'switch_property_address',
        type: 'form',
        header: 'FLOW_PROPERTY_ADDRESS_QUESTION',
        next: 'switch_property_value',
        gap: 15,
        column: 1,
        skipApiCall: true,
        inputs: [
          {
            id: 'property_address',
            label: 'FLOW_PROPERTY_ADDRESS',
            type: 'address',
            fieldName: 'address',
            inputProps: {
              validateProvince: true,
            },
          },
        ],
      },
      switch_property_value: {
        id: 'switch_property_value',
        header: 'FLOW_SWITCH_MORTGAGE_AMOUNT',
        type: 'form',
        next: 'switch_know_closingDate',
        skipApiCall: true,
        inputs: [
          {
            id: 'loanAmount',
            type: 'loanAmount',
          },
        ],
      },
      switch_know_closingDate: {
        id: 'switch_know_closingDate',
        type: 'options',
        header: 'FLOW_KNOW_SWITCH_DATE',
        next: FlowState.SEPARATE,
        gap: 15,
        column: 1,
        skipApiCall: true,
        options: [
          {
            id: 'YES_I_KNOW',
            value: 'true',
            label: 'FLOW_YES',
            next: 'switch_date',
          },
          {
            id: 'NOT_SURE',
            value: 'false',
            label: 'FLOW_NOT_SURE_YET',
            next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
          },
        ],
      },
      switch_date: {
        id: 'switch_date',
        header: 'FLOW_WHEN_SWITCH_DATE',
        type: 'form',
        next: isWealthsimple ? 'ws_client_type' : FlowState.NEXTSECTION,
        subHeader: () => (restrictClosingDate ? <Alert type="warning" message="CLOSING_DATE_ERROR" /> : undefined),
        inputs: [
          {
            id: 'switchDate',
            label: 'FLOW_SWITCH_DATE',
            type: 'date',
            fieldName: 'closingDate',
            from: new Date(),
            to: addYears(new Date(), 100),
            rules: {
              required: {
                value: true,
                message: 'ERROR_CLOSING_DATE_REQUIRED',
              },
            },
          },
        ],
      },
      ws_client_type: {
        id: 'ws_client_type',
        type: 'options',
        header: 'FLOW_WEALTHSIMPLE_EXISTING_AMOUNT',
        subHeader: isCashbackActive
          ? 'FLOW_WEALTHSIMPLE_EXISTING_AMOUNT_CASHBACK_SUB_TITLE'
          : 'FLOW_WEALTHSIMPLE_EXISTING_AMOUNT_SUB_TITLE',
        skipApiCall: true,
        next: body?.dealPurpose === DealPurpose.Refinance || isCashbackActive ? FlowState.NEXTSECTION : 'ws_transfer_amount',
        fieldName: 'currentWealthsimpleBalance',
        options: Object.entries(WS_TIER).map(([key, value]) => ({
          id: key,
          value: WS_TIER_MAP[value],
          label: <TierOption tier={value} />,
          next: body?.dealPurpose === DealPurpose.Refinance || isCashbackActive ? FlowState.NEXTSECTION : 'ws_transfer_amount',
        })),
      },
      ...(!isCashbackActive && {
        ws_transfer_amount: {
          id: 'ws_transfer_amount',
          type: 'form',
          header: 'FLOW_WEALTHSIMPLE_TRANSFER_AMOUNT',
          subHeader: 'FLOW_WEALTHSIMPLE_TRANSFER_AMOUNT_SUB_TITLE',
          next: FlowState.NEXTSECTION,
          skipApiCall: true,
          inputs: [
            {
              id: 'newMoneyToWealthsimple',
              label: 'FLOW_WEALTHSIMPLE_TRANSFER_AMOUNT_LABEL',
              type: 'input',
              filter: 'currency',
              fieldName: 'newMoneyToWealthsimple',
              placeholder: '$0',
              rules: {
                required: {
                  value: true,
                  message: 'ERROR_TRANSFER_AMOUNT_REQUIRED',
                },
              },
            },
          ],
          footer: <TierRebate />,
        },
      }),
    }),
    [propertyOccupationType, isWealthsimple, isCashbackActive, body?.dealPurpose, restrictClosingDate]
  );
};

export default useShortAppForm;
