import { API } from '@models/enums';
import { LosControllerApiApiLosApplicationIdPostRequest } from '@pinecorpca/evergreen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { losApi } from 'api/evergreen';
import { useRouter } from 'next/router';

export const useLosMutation = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: LosControllerApiApiLosApplicationIdPostRequest) => losApi.apiLosApplicationIdPost(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.MORTGAGE_APPLICATION, applicationId as string] });
      queryClient.invalidateQueries({ queryKey: [API.ENTITIES] });
    },
  });
};
