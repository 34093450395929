import { Button } from '@pinecorpca/spruce';
import styled from 'styled-components';

const StyledRegister = {
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      flex-direction: column;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      gap: 25px;
      margin-bottom: 50px;
    }
  `,
  Title: styled.h1`
    font-family: var(--font-tiempos);
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: ${({ theme }) => theme.text.primary};
    white-space: break-spaces;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      font-size: 24px;
      line-height: 30px;
    }
  `,
  FromWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Form: styled.form`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: flex;
      flex-direction: column;
      align-items: unset;
    }
  `,
  CheckBoxWrapper: styled.div`
    grid-column: 1/3;
  `,
  FooterWrapper: styled.div`
    grid-column: 1/3;
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
  SubmitButton: styled(Button)`
    width: 240px;
    align-self: flex-end;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      width: 100%;
    }
  `,
  Modal: styled.div`
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .modal-heading {
      font-family: var(--font-tiempos);
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      color: ${({ theme }) => theme.text.primary};
    }

    .button-wrapper {
      align-self: flex-end;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoint.sm}) {
        width: 100%;
      }
    }

    .modal-content {
      margin: 20px 0 12px 0;
      font-family: var(--font-futura);
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.text.secondary};
    }
  `,
};

export default StyledRegister;
