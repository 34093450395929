import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faMemoCircleCheck, faMessageQuestion } from '@fortawesome/pro-light-svg-icons';
import { CUSTOMER_PORTAL_ROUTES, NAMESPACE, ROUTES } from '@models/enums';
import { DealPurpose, PropertyOccupationType } from '@pinecorpca/evergreen';
import { useEntitiesQuery } from 'hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { formatAddress } from 'utils';

export type Link = {
  label: string;
  href: string;
  id: string;
};

export type SubLink = {
  title: string;
  parentId?: string;
  links: Link[];
};

export type HeaderLink = {
  id: 'application' | 'documents' | 'support';
  label: string | undefined;
  href: string;
  subLink?: SubLink;
  icon?: IconProp;
  title?: string;
};

export const useNavLinks = (): HeaderLink[] => {
  const { data: entities } = useEntitiesQuery();
  const {
    query: { applicationId },
  } = useRouter();
  const { t } = useTranslation(NAMESPACE.CUSTOMERPORTAL);
  const currentApplication = useMemo(() => entities?.find((entity) => entity.application.id === applicationId), [entities, applicationId]);
  const hasMultipleMortgages = (entities?.length || 0) > 1;

  const mortgageLinks = useMemo<Link[] | undefined>(
    () =>
      entities?.map((entity) => ({
        title: t('DASHBOARD_MY_MORTGAGES'),
        id: entity.application.id as string,
        label:
          entity.application?.dealPurpose === DealPurpose.PreApproval
            ? t(DealPurpose.PreApproval, { ns: NAMESPACE.COMMON })
            : (formatAddress(entity.application.property?.propertyAddress, { excludePostalCode: true }) as string),
        href: `/${ROUTES.CUSTOMER_PORTAL}/${entity.application.id}/${
          !!entity?.servicingApplication ? CUSTOMER_PORTAL_ROUTES.SERVICING : CUSTOMER_PORTAL_ROUTES.DASHBOARD
        }`,
      })),
    [entities, t]
  );

  const links = useMemo<HeaderLink[]>(() => {
    const baseLinks: HeaderLink[] = [
      {
        id: 'application',
        label: t(hasMultipleMortgages ? 'DASHBOARD_MY_MORTGAGES' : 'DASHBOARD_MY_MORTGAGE'),
        href: `/${ROUTES.CUSTOMER_PORTAL}/${applicationId}/${
          !!currentApplication?.servicingApplication ? CUSTOMER_PORTAL_ROUTES.SERVICING : CUSTOMER_PORTAL_ROUTES.DASHBOARD
        }`,
        icon: faMemoCircleCheck,
        subLink: hasMultipleMortgages
          ? {
              title: t('DASHBOARD_MY_MORTGAGES'),
              links: mortgageLinks || [],
              parentId: 'application',
            }
          : undefined,
      },
      {
        id: 'documents',
        label: t('DOCUMENTS_MY'),
        href: `/${ROUTES.CUSTOMER_PORTAL}/${applicationId}/${CUSTOMER_PORTAL_ROUTES.DOCUMENTS}`,
        icon: faFolder,
      },
    ];

    if (currentApplication?.application.property?.intendedOccupationType !== PropertyOccupationType.Rental) {
      const supportLink: HeaderLink = {
        id: 'support',
        label: t('SUPPORT'),
        href: `/${ROUTES.CUSTOMER_PORTAL}/${applicationId}/${CUSTOMER_PORTAL_ROUTES.CONTACT}`,
        icon: faMessageQuestion,
      };

      if (!applicationId) {
        return [];
      }

      return [...baseLinks, supportLink];
    }

    return baseLinks;
  }, [
    t,
    hasMultipleMortgages,
    applicationId,
    currentApplication?.servicingApplication,
    currentApplication?.application.property?.intendedOccupationType,
    mortgageLinks,
  ]);

  return links;
};
