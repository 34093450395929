/* eslint-disable max-len */
import { API } from '@models/enums/api.enum';
import {
  MortgageApplicationClientDto,
  MortgageApplicationControllerApiApiMortgageApplicationsApplicationIdDeleteRequest as MortgageApplicationDeleteRequest,
  MortgageApplicationControllerApiApiMortgageApplicationsApplicationIdPatchRequest as MortgageApplicationPatchRequest,
} from '@pinecorpca/evergreen';
import { mortgageApplicationApi } from 'api/evergreen';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ROUTES } from '@models/enums';
import { AxiosError } from 'axios';

export const useMortgageAppQuery = <T = MortgageApplicationClientDto>(
  select?: (data: MortgageApplicationClientDto) => T,
  refetchOnWindowFocus?: boolean | 'always'
) => {
  const {
    query: { applicationId },
    replace,
  } = useRouter();

  const { data, isLoading, isSuccess, isFetching, isError, isRefetching, error } = useQuery({
    queryKey: [API.MORTGAGE_APPLICATION, applicationId],
    queryFn: async () => {
      const { data } = await mortgageApplicationApi.apiV2MortgageApplicationsApplicationIdGet({
        applicationId: applicationId as string,
      });
      return data;
    },
    enabled: !!applicationId,
    refetchOnWindowFocus: refetchOnWindowFocus ?? false,
    select,
  });

  useEffect(() => {
    if (!isError) return;

    const response = (error as AxiosError)?.response;
    const is404Error = response?.status === 404;
    const isUUIDError = (response?.status || 0) >= 500 && !!response?.data?.message?.toUpperCase()?.includes('UUID');

    if (is404Error || isUUIDError) {
      replace(ROUTES.NOT_FOUND);
    }
  }, [error, isError, replace]);

  return {
    data: data as T,
    isLoading,
    isSuccess,
    isFetching,
    isRefetching,
  };
};

export const useMortgageAppMutation = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();
  return useMutation({
    mutationFn: (payload: MortgageApplicationPatchRequest) => mortgageApplicationApi.apiMortgageApplicationsApplicationIdPatch(payload),
    onSuccess: ({ data: updatedMortgageApplication }) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          ...updatedMortgageApplication,
        })
      );
    },
  });
};

export const useMortgageAppOnDelete = () =>
  useMutation({
    mutationFn: (payload: MortgageApplicationDeleteRequest) => mortgageApplicationApi.apiMortgageApplicationsApplicationIdDelete(payload),
  });
