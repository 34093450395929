import Styled from './PinePromotion.styled';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { NAMESPACE } from '@models/enums';
import config from '@config';

const PinePromotion = () => {
  const { t } = useTranslation(NAMESPACE.PROMOTION);
  return (
    <Styled.Container>
      <Styled.ImageWrapper>
        <Image
          src={`${config.IMAGES_BUCKET}celebration-group-confetti.svg`}
          alt={t('PROMOTION_REFERRAL')}
          fill
          style={{ objectFit: 'contain' }}
          priority
        />
      </Styled.ImageWrapper>
      <Styled.Wrapper>
        <Styled.Title data-testid="promotion-title">
          <Trans i18nKey="PROMOTION_REFERRAL" t={t} components={{ bold: <Styled.Strong /> }} />
        </Styled.Title>
        <Styled.Link target="_blank" href="https://www.pine.ca/customer-referral" data-testid="promotion-link">
          {t('LEARN_MORE')}
        </Styled.Link>
      </Styled.Wrapper>
    </Styled.Container>
  );
};

export default PinePromotion;
