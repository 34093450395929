import styled from 'styled-components';

const StyledDesktopNav = {
  Nav: styled.nav`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  MenuList: styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    gap: 52px;
    margin: 0;
    padding: 0;
  `,
  ActionWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
};

export default StyledDesktopNav;
