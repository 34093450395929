import styled from 'styled-components';

export const StyledAddEntryButton = {
  Button: styled.button`
    all: unset;
    padding: 32px;
    border: 1px solid ${({ theme }) => theme.border.primary};
    border-radius: 12px;

    display: flex;
    align-items: center;
    gap: 10px;

    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.text.primary};
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.border.active};
    }

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 24px;
      font-size: 18px;
      line-height: 20px;
    }
  `,
};

export default StyledAddEntryButton;
