import styled from 'styled-components';

const StyledPromotion = {
  Container: styled.div`
    position: relative;

    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.border.primary};
    background: ${({ theme }) => theme.background.secondary};
    height: 100%;
    width: 550px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    padding: 24px;
    max-width: 264px;
  `,
  Title: styled.h2`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      text-align: center;
    }
  `,
  Strong: styled.strong`
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-tiempos);
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
  `,
  Link: styled.a`
    border-radius: 100px;
    border: 2px solid ${({ theme }) => theme.palette.pine.default};
    background-color: transparent;
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 14px;
    line-height: 14px;

    padding: 8px 15px;
    text-decoration: none;
    width: fit-content;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.palette.pine.default};
      color: ${({ theme }) => theme.text.invert};
    }
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 100%;
      text-align: center;
    }
  `,
  ImageWrapper: styled.div`
    position: relative;
    top: 9px;
    width: 200px;
    height: 180px;
    margin: 0 24px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 100%;
      height: 167px;
    }
  `,
};

export default StyledPromotion;
