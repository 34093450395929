import { FlowRoot } from '@models/flow.model';
import { DealPurpose } from '@pinecorpca/evergreen';
import { preApprovalPropertyForm, purchasePropertyForm, refinancePropertyForm, switchPropertyForm } from '../config/flow.config';

export const getPropertyFlow = (dealPurpose: DealPurpose): FlowRoot => {
  switch (dealPurpose) {
    case DealPurpose.Refinance:
      return refinancePropertyForm;
    case DealPurpose.SwitchTransfer:
      return switchPropertyForm;
    case DealPurpose.PreApproval:
      return preApprovalPropertyForm;
    default:
      return purchasePropertyForm;
  }
};
