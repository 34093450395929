import { PropertyTypeOptions } from '@containers/short-app/models/intentions.model';
import { LONG_APP_ROUTES } from '@models/enums';
import { API } from '@models/enums/api.enum';
import { FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { ConstructionType, PropertyOccupationType, PurchaseTimeframe } from '@pinecorpca/evergreen';

export const propertyIntentions = [
  {
    id: 'OWNER_OCCUPIED',
    value: PropertyOccupationType.OwnerOccupied,
    label: 'PROPERTY_USAGE_PRIMARY',
  },
  {
    id: 'RENTAL',
    value: PropertyOccupationType.Rental,
    label: 'PROPERTY_USAGE_INVESTMENT',
    invalid: true,
    errorMessage: 'FLOW_REFINANCE_PROPERTY_USAGE_INVESTMENT_ERROR',
  },
  {
    id: 'SECOND_HOME',
    value: PropertyOccupationType.SecondHome,
    label: 'PROPERTY_USAGE_SECOND',
  },
];

export const purchasePropertyForm: FlowRoot = {
  root: 'property_usage',
  id: 'property',
  pageTitle: 'PROPERTY_PAGE_TITLE',
  flowName: 'property',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.APPLICANT_INFO}`,
  property_usage: {
    id: 'property_usage',
    type: 'options',
    header: 'FLOW_PROPERTY_ADDRESS_HEADER',
    next: 'property_address',
    api: API.PROPERTY,
    style: { gap: 15, gridTemplateColumns: '1fr' },
    fieldName: 'intendedOccupationType',
    secondaryFieldName: 'property.intendedOccupationType',
    options: propertyIntentions,
    tagLabel: 'PROPERTY_USAGE',
  },
  property_address: {
    id: 'property_address',
    type: 'form',
    header: 'PROPERTY_ADDRESS_HEADER',
    next: 'property_type',
    style: { gap: 15, gridTemplateColumns: '1fr' },
    api: API.PROPERTY,
    inputs: [
      {
        id: 'property_address',
        label: 'FLOW_PROPERTY_ADDRESS',
        type: 'address',
        fieldName: 'propertyAddress',
        secondaryFieldName: 'property.propertyAddress',
      },
    ],
  },
  property_type: {
    id: 'property_type',
    type: 'options',
    header: 'FLOW_PROPERTY_TYPE',
    next: 'existing_new_build',
    api: API.PROPERTY,
    style: { gap: 35, gridTemplateColumns: '1fr 1fr' },
    fieldName: 'propertyType',
    secondaryFieldName: 'property.propertyType',
    options: PropertyTypeOptions,
    tagLabel: 'PROPERTY_TYPE',
  },
  existing_new_build: {
    id: 'existing_new_build',
    type: 'options',
    header: 'PROPERTY_PURCHASE_TYPE_HEADER',
    next: 'downpayment',
    style: { gap: 15, gridTemplateColumns: '1fr' },
    api: API.PROPERTY,
    fieldName: 'constructionType',
    secondaryFieldName: 'property.constructionType',
    options: [
      {
        id: 'existing',
        value: ConstructionType.Existing,
        label: 'PROPERTY_PURCHASE_TYPE_OPTION1',
      },
      {
        id: 'new_build',
        value: ConstructionType.New,
        label: 'PROPERTY_PURCHASE_TYPE_OPTION2',
      },
    ],
    tagLabel: 'NEW_EXISTING',
  },
  downpayment: {
    id: 'downpayment',
    type: 'form',
    header: 'PROPERTY_DOWNPAYMENT_HEADER',
    next: FlowState.NEXTSECTION,
    fieldName: 'totalDownPayment',
    api: API.MULTIPLE,
    inputs: [
      {
        id: 'downpayment',
        type: 'downpayment',
      },
    ],
  },
};

export const refinancePropertyForm: FlowRoot = {
  root: 'property_usage',
  id: 'refinanceProperty',
  pageTitle: 'PROPERTY_PAGE_TITLE',
  flowName: 'propertySwitch',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.APPLICANT_INFO}`,
  property_usage: {
    id: 'property_usage',
    type: 'options',
    header: 'FLOW_REFINANCE_PROPERTY_USAGE',
    next: 'property_address',
    api: API.PROPERTY,
    style: { gap: 15, gridTemplateColumns: '1fr 1fr' },
    fieldName: 'intendedOccupationType',
    secondaryFieldName: 'property.intendedOccupationType',
    options: propertyIntentions,
    tagLabel: 'PROPERTY_USAGE',
  },
  property_address: {
    id: 'property_address',
    type: 'form',
    header: 'PROPERTY_ADDRESS_SWITCH_HEADER',
    next: 'downpayment',
    style: { gap: 15, gridTemplateColumns: '1fr 1fr' },
    api: API.PROPERTY,
    inputs: [
      {
        id: 'property_address',
        label: 'FLOW_PROPERTY_ADDRESS',
        type: 'address',
        fieldName: 'propertyAddress',
        secondaryFieldName: 'property.propertyAddress',
      },
    ],
  },
  downpayment: {
    id: 'downpayment',
    type: 'form',
    header: 'PROPERTY_DEAL_LOAN_AMOUNT_HEADER',
    next: FlowState.NEXTSECTION,
    api: API.MULTIPLE,
    style: { gap: 15, gridTemplateColumns: '1fr 1fr' },
    fieldName: 'loanAmount',
    inputs: [
      {
        id: 'loanAmount',
        type: 'loanAmount',
        inputProps: { refinance: true },
      },
    ],
  },
};

export const switchPropertyForm: FlowRoot = {
  root: 'property_usage',
  id: 'switchProperty',
  pageTitle: 'PROPERTY_PAGE_TITLE',
  flowName: 'propertySwitch',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.APPLICANT_INFO}`,
  property_usage: {
    id: 'property_usage',
    type: 'options',
    header: 'FLOW_REFINANCE_PROPERTY_USAGE',
    next: 'property_address',
    api: API.PROPERTY,
    style: { gap: 15, gridTemplateColumns: '1fr' },
    fieldName: 'intendedOccupationType',
    secondaryFieldName: 'property.intendedOccupationType',
    options: propertyIntentions,
    tagLabel: 'PROPERTY_USAGE',
  },
  property_address: {
    id: 'property_address',
    type: 'form',
    header: 'PROPERTY_ADDRESS_SWITCH_HEADER',
    next: 'downpayment',
    style: { gap: 15, gridTemplateColumns: '1fr' },
    api: API.PROPERTY,
    inputs: [
      {
        id: 'property_address',
        label: 'FLOW_PROPERTY_ADDRESS',
        type: 'address',
        fieldName: 'propertyAddress',
        secondaryFieldName: 'property.propertyAddress',
      },
    ],
  },
  downpayment: {
    id: 'downpayment',
    type: 'form',
    header: 'PROPERTY_DEAL_LOAN_AMOUNT_HEADER',
    next: FlowState.NEXTSECTION,
    api: API.MULTIPLE,
    style: { gap: 15, gridTemplateColumns: '1fr' },
    fieldName: 'loanAmount',
    inputs: [
      {
        id: 'loanAmount',
        type: 'loanAmount',
      },
    ],
  },
};

export const preApprovalPropertyForm: FlowRoot = {
  root: 'property_usage',
  id: 'switchRefinanceProperty',
  pageTitle: 'PROPERTY_PAGE_TITLE',
  flowName: 'propertyPreApproval',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.APPLICANT_INFO}`,
  property_usage: {
    id: 'property_usage',
    type: 'options',
    header: 'FLOW_PROPERTY_ADDRESS_HEADER',
    next: 'property_address',
    api: API.PROPERTY,
    style: { gap: 15, gridTemplateColumns: '1fr' },
    fieldName: 'intendedOccupationType',
    secondaryFieldName: 'property.intendedOccupationType',
    options: propertyIntentions,
    tagLabel: 'PROPERTY_USAGE',
  },
  property_address: {
    id: 'property_address',
    type: 'form',
    header: 'FLOW_INTERESTED_AREA',
    next: 'property_type',
    style: { gap: 15, gridTemplateColumns: '1fr' },
    api: API.MORTGAGE_APPLICATION,
    inputs: [
      {
        id: 'property_address',
        label: 'Address',
        type: 'autocomplete',
        hideLabel: true,
        placeholder: 'FLOW_ADDRESS_INTERESTED',
        fieldName: 'expectedRegion',
        rules: {
          required: {
            value: true,
            message: 'ADDRESS_INTERESTED_ERROR',
          },
        },
      },
    ],
  },
  property_type: {
    id: 'property_type',
    type: 'options',
    header: 'FLOW_PROPERTY_TYPE',
    next: 'downpayment',
    style: { gap: 25, gridTemplateColumns: '1fr 1fr' },
    api: API.PROPERTY,
    fieldName: 'propertyType',
    secondaryFieldName: 'property.propertyType',
    options: PropertyTypeOptions,
    tagLabel: 'PROPERTY_TYPE',
  },
  downpayment: {
    id: 'downpayment',
    type: 'form',
    header: 'PROPERTY_PRE_APPROVAL_DWNPMT_HEADER',
    next: 'researching_plan_to_buy',
    api: API.MULTIPLE,
    inputs: [
      {
        id: 'downpayment',
        type: 'downpayment',
        inputProps: {
          isPreApproval: true,
        },
      },
    ],
  },
  researching_plan_to_buy: {
    id: 'researching_plan_to_buy',
    type: 'options',
    next: 'researching_realtor',
    header: 'PRE_APPROVAL_PLAN_TO_BUY',
    fieldName: 'purchaseTimeframe',
    api: API.MORTGAGE_APPLICATION,
    options: Object.entries(PurchaseTimeframe).map(([key, value]) => ({
      id: key,
      value,
      label: `FLOW_${value}`,
      next: 'researching_realtor',
    })),
  },
  researching_realtor: {
    id: 'researching_realtor',
    type: 'options',
    header: 'PRE_APPROVAL_REALTOR',
    next: FlowState.NEXTSECTION,
    api: API.MORTGAGE_APPLICATION,
    fieldName: 'workingWithRealtor',
    options: [
      {
        id: 'YES',
        value: 'true',
        label: 'FLOW_YES',
      },
      {
        id: 'NO',
        value: 'false',
        label: 'FLOW_NO',
      },
    ],
  },
};
